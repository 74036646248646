import { lazy } from 'react';

// project imports
import MainLayout from '../NewLayout/MainLayout';
import Loadable from '../ui-component/Loadable';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('../views/dashboard/Default')));

// inmuebles routing
const InmueblesDisponibles = Loadable(lazy(() => import('../views/inmuebles/inmuebles-disponibles')));

// catalogos routing
const Inmuebles     = Loadable(lazy(() => import('../views/inmuebles/inmuebles')));
const Inquilinos    = Loadable(lazy(() => import('../views/inquilinos')));
const Fiadores      = Loadable(lazy(() => import('../views/fiadores')));
const Directorio    = Loadable(lazy(() => import('../views/directorio')));
const TiposInmueble = Loadable(lazy(() => import('../views/inmuebles/tipo-de-inmueble')));
const TiposServicio = Loadable(lazy(() => import('../views/tipos-de-servicio')));
const TipoDePersonal= Loadable(lazy(() => import ('../views/tipo-de-personal')));
const Personal      = Loadable(lazy( () => import('../views/personal')));
const TiposFormato  = Loadable(lazy( () => import('../views/tipo-de-formato')));
const Duenios       = Loadable(lazy( () => import ('../views/duenios')));
const Prioridad     = Loadable(lazy(() => import('../views/prioridad')));
const TiposCargo    = Loadable(lazy(() => import('../views/tipos-de-cargo')));
const TiposCobro    = Loadable(lazy( () => import('../views/tipos-de-cobro')));
const Margen        = Loadable(lazy(() => import('../views/margen')));
const Periodo       = Loadable(lazy(() => import('../views/periodo')));
const Folios        = Loadable(lazy(() => import('../views/folios-facturas')));
const MetodoPago    = Loadable(lazy(() => import('../views/metodo-de-pago')));
const FoliosCBB     = Loadable(lazy(() => import('../views/folios-cbb')));
const Asesores      = Loadable(lazy( () => import ('../views/asesores')));
const Apoderados    = Loadable(lazy( () => import ('../views/apoderados')));

// admin routing
const TiposContrato = Loadable(lazy(() => import('../views/administracion/tiposContrato')));
const RegimenFiscal = Loadable(lazy(() => import('../views/administracion/regimenFiscal')));
const UsoCFDI       = Loadable(lazy(() => import('../views/administracion/usoCFDI')));
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <DashboardDefault />
        },
        {
            path: 'dashboard',
            children: [
                {
                    path: 'default',
                    element: <DashboardDefault />
                }
            ]
        },
        {
            path: 'catalogos',
            children: [
                {
                    path: 'inmuebles',
                    element: <Inmuebles />
                },
                {
                    path: 'inquilinos',
                    element: <Inquilinos />
                },
                {
                    path: 'fiadores',
                    element: <Fiadores/>
                },
                {
                    path: 'directorio',
                    element: <Directorio/>
                },
                {
                    path: 'tiposInmueble',
                    element: <TiposInmueble/>
                },
                {
                    path: 'tiposServicio',
                    element: <TiposServicio/>
                },
                {
                    path: 'tiposPersonal',
                    element: <TipoDePersonal/>
                },
                {
                    path: 'personal',
                    element: <Personal/>
                },
                {
                    path: 'tiposFormato',
                    element: <TiposFormato/>
                },
                {
                    path: 'duenios',
                    element: <Duenios />
                },
                {
                    path: 'prioridad',
                    element: <Prioridad/>
                },
                {
                    path: 'tiposCargo',
                    element: <TiposCargo/>
                },
                {
                    path: 'tiposCobro',
                    element: <TiposCobro/>
                },
                {
                    path: 'margen',
                    element: <Margen/>
                },
                {
                    path: 'periodo',
                    element: <Periodo/>
                },
                {
                    path: 'folios',
                    element: <Folios/>
                },
                {
                    path: 'metodosPago',
                    element: <MetodoPago/>
                },
                {
                    path: 'foliosCBB',
                    element: <FoliosCBB/>
                },
                {
                    path: 'asesores',
                    element: <Asesores />
                },
                {
                    path: 'apoderados',
                    element: <Apoderados />
                }
            ]
        },
        {
            path: 'inmuebles',
            children: [
                {
                    path: 'inmueblesDisponibles',
                    element: <InmueblesDisponibles />
                }
            ]
        },
        {
            path: 'administracion',
            children: [
                {
                    path: 'tiposContrato',
                    element: <TiposContrato />
                },
                {
                    path: 'regimenFiscal',
                    element: <RegimenFiscal />
                },
                {
                    path: 'usoCFDI',
                    element: <UsoCFDI />
                }
            ]
        },
    ]
};

export default MainRoutes;